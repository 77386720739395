import { BLOCK_NAME, ALERT_CONTENT } from '../constants'

function createTemplate() {
  const template = document.createElement('div')

  template.classList.add(BLOCK_NAME)
  template.textContent = ALERT_CONTENT

  return template
}

export {
  createTemplate,
}
