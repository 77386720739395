import 'libs/magnific-popup/magnific-popup.css'
import 'libs/magnific-popup/jquery.magnific-popup.min'

const $bChooseTownPopup = $('.b-choose-town-popup') // элемент попапа с выбором города
const $towns = $bChooseTownPopup.find('.b-choose-town-popup__towns') // контейнер внутри попапа со списком городов
const $bTownSwitcher = $('.b-pd-nav__title-town-link')
const mfp = $.magnificPopup.instance

$bTownSwitcher.magnificPopup({
  type: 'inline',
  removalDelay: 200,
  closeOnBgClick: true,
  callbacks: {
    beforeOpen() {
      this.st.mainClass = 'mag-popup'
      this.st.mainClass += ` ${this.st.el.attr('data-effect')}`
    },
  },
})

$bTownSwitcher.on('click', e => {
  e.preventDefault()

  if ($towns.text() === '') {
    $.ajax({
      type: 'GET',
      url: $(e.target).data('url'),
      success(data) {
        $towns.html(data)
        setTimeout(() => mfp.updateItemHTML(), 200)
      },
    })
  }
})
