function convertToArray(nodeList) {
  return Array.prototype.slice.call(nodeList)
}

function getCookie(cname) {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]

    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function getCsrfToken() {
  return getCookie('csrftoken')
}

function handleFormSubmit(evt) {
  evt.preventDefault()

  if (evt.target.querySelector('[name="csrfmiddlewaretoken"]')) {
    evt.target.submit()
    return
  }

  const inputWithCsrf = document.createElement('input')

  inputWithCsrf.setAttribute('name', 'csrfmiddlewaretoken')
  inputWithCsrf.setAttribute('type', 'hidden')
  inputWithCsrf.setAttribute('value', getCsrfToken())

  evt.target.appendChild(inputWithCsrf)
  evt.target.submit()
}

const prgForms = convertToArray(document.querySelectorAll('.b-prg_csrf'))

prgForms.forEach(form => {
  form.addEventListener('submit', handleFormSubmit)
})
